<template>
  <b-card
    title="สร้างรายการปรับปรุงค่าเช่า เลือกเป็นรายคัน"
    class="mt-1"
  >
    <drop-rental-popup-reg-num
      :temp-search-reg-num="tempSearchRegNum"
      :is-popup-reg-num-active.sync="isPopupRegNumActive"
      :acc-cod-mkt="salesCode"
      open-popup-from="dropRentalCar"
      @select-item="fncSelectRegNum"
    />
    <b-modal
      ref="refModalDatePicker"
      centered
      title="ข้อมูลวันที่"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
      @hidden="fncResetDatePickerValue()"
    >
      <v-date-picker
        v-model="datePickerActive"
        class="mt-6"
        :min="setMinDatePicker"
        :max="setMaxDatePicker"
        locale="th"
        @input="fncClickSelectDatepicker"
      />
    </b-modal>
    <b-modal
      ref="refModalAlertDataOverDue"
      v-b-modal.modal-danger
      centered
      hide-footer
      modal-class="modal-danger"
    >
      <template #modal-title>
        <feather-icon icon="AlertCircleIcon" /> แจ้งเตือน
      </template>
      <div class="text-center mt-1 mb-2">
        <feather-icon
          icon="XCircleIcon"
          size="40"
          class="text-danger"
        />
      </div>
      <div class="d-block text-center mt-2 mb-2">
        <span>กรุณากรอกข้อมูล <b>ยอด Drop</b> อย่างน้อย 1 รายการ</span>
      </div>
    </b-modal>
    <b-modal
      ref="refModalAlertDropRemark"
      centered
      hide-footer
      modal-class="modal-danger"
    >
      <template #modal-title>
        <feather-icon icon="AlertCircleIcon" /> แจ้งเตือน
      </template>
      <div class="d-block text-center mt-2 mb-2">
        <span>กรุณากรอกข้อมูล <b>หมายเหตุ</b> ให้ครบถ้วน</span>
      </div>
    </b-modal>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เซลล์"
              label-for="salesName"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เซลล์
                </div>
              </template>
              <b-form-input
                id="salesName"
                :value="salesName"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="วันที่ทำรายการ"
              label-for="accesstime"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  วันที่ทำรายการ
                </div>
              </template>
              <b-form-input
                id="accesstime"
                :value="resolveFormatDate(accesstime)"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="inputSearchRegNum"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  ค้นหาเลขทะเบียน
                </div>
              </template>
              <b-input-group v-show="!regNum1 && !regNum2">
                <b-form-input
                  id="inputSearchRegNum"
                  v-model="inputSearchRegNum1"
                  placeholder="เลขทะเบียน"
                  trim
                  style="display: inline;width: 40%;"
                  @keydown.enter.native="refInputSearchRegNum2.focus()"
                />
                <b-form-input
                  id="inputSearchRegNum2"
                  ref="refInputSearchRegNum2"
                  v-model="inputSearchRegNum2"
                  placeholder="เลขทะเบียน"
                  trim
                  style="display: inline;width: 40%;  margin-left: 5px;"
                  @keydown.enter.native="fncSearcRegNum(inputSearchRegNum1, inputSearchRegNum2)"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupRegNum()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="regNum1 || regNum2">
                <b-form-input
                  id="inputSearchRegNum"
                  v-model="regNum1"
                  placeholder="เลขทะเบียน"
                  trim
                  style="display: inline;width: 40%;"
                  disabled
                />
                <b-form-input
                  id="inputSearchRegNu2"
                  v-model="regNum2"
                  placeholder="เลขทะเบียน"
                  trim
                  style="display: inline;width: 40%; margin-left: 5px;"
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelRegNum()"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="ชื่อลูกค้า"
              label-for="customerName"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  ชื่อลูกค้า
                </div>
              </template>
              <b-form-input
                id="customerName"
                :value="customerName"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="หมายเลขตัวถัง"
              label-for="chassisNum"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  หมายเลขตัวถัง
                </div>
              </template>
              <b-form-input
                id="chassisNum"
                :value="chassisNum"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="พอร์ต"
              label-for="branchCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  พอร์ต
                </div>
              </template>
              <b-input-group>
                <b-form-input
                  id="branchCode"
                  style="display: inline;width: 40%;"
                  :value="branchCode"
                  trim
                  disabled
                />
                <b-form-input
                  id="branchName"
                  style="display: inline;width: 40%; margin-left: 5px;"
                  :value="branchName"
                  trim
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เลขที่สัญญา"
              label-for="conNum"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เลขที่สัญญา
                </div>
              </template>
              <b-form-input
                id="conNum"
                :value="conNum"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="สหกรณ์"
              label-for="cooperateCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  สหกรณ์
                </div>
              </template>
              <b-form-input
                id="cooperateCode"
                :value="cooperateCode"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เซลล์แมน"
              label-for="salesMan"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เซลล์แมน
                </div>
              </template>
              <b-form-input
                id="salesMan"
                :value="salesMan"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-card bg-variant="light">
              <b-row class="delete-margin">
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="วันที่ปัจจุบัน"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    {{ resolveFormatDate(accesstime) }}
                  </b-form-group>
                </b-col>
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอดเช่ารวม"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(rentTotalAmt).toLocaleString() }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="delete-margin">
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12" />
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอดคงเหลือ"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(outStandingAmt).toLocaleString() }}
                      </b-col>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ `${Number(outstandingPercent).toLocaleString()}%` }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="delete-margin">
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12" />
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอดชำระแล้ว"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(paidAmt).toLocaleString() }}
                      </b-col>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ `${Number(paidPercent).toLocaleString()}%` }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="delete-margin">
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12" />
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอดค้างชำระ"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(overdueAmt).toLocaleString() }}
                      </b-col>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ `${Number(overduePercent).toLocaleString()}%` }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="delete-margin">
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12" />
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอดชำระไม่ถึง"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(futureAmt).toLocaleString() }}
                      </b-col>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ `${Number(futurePercent).toLocaleString()}%` }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12" />
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label="ยอด Drop"
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ Number(dropAmt).toLocaleString() }}
                      </b-col>
                      <b-col
                        :cols="$store.state.app.windowWidth >= 800 ? 6 : 12"
                        class="text-right"
                      >
                        {{ `${Number(dropPercent).toLocaleString()}%` }}
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col :cols="$store.state.app.windowWidth >= 800 ? 4 : 12">
                  <b-form-group
                    label-cols-md="4"
                    label-cols-sm="12"
                  >
                    <b-row>
                      <b-col class="text-right text-danger">
                        {{ Number(sumDropRantalFutureAmount + sumDropRentalOverdueAmount).toLocaleString() }}
                      </b-col>
                      <b-col class="text-right text-danger">
                        ??
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-table-simple
          caption-top
          bordered
          class="position-relative mb-1"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                colspan="7"
                class="text-center"
              >
                <b>เลยกำหนดชำระ</b>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>วันครบกำหนด</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>ค่าเช่า</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>ชำระ</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>ค้างชำระ</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 200px;max-width: 200px;"
              >
                <b>ยอด Drop</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>ค่าเช่าใหม่</b>
              </b-th>
              <b-th class="text-center">
                <b>หมายเหตุ</b>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="dataOverDue.length">
              <b-tr
                v-for="(item, index) in dataOverDue"
                :key="index"
              >
                <b-td class="text-center">
                  {{ resolveFormatDate(item.due_date) }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(item.due_amount).toLocaleString() }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(item.paid_amount).toLocaleString() }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(item.remain_amount).toLocaleString() }}
                </b-td>
                <b-td>
                  <b-form-input
                    v-model="item.dropAmount"
                    type="number"
                    autocomplete="off"
                    placeholder="ยอด Drop"
                    :state="item.dropAmount ? true : null"
                    @input="fncUpdateOverDueDropAmount(index)"
                  />
                  <!-- <small class="text-danger">
                    {{ validationContext.errors[0] ? 'กรุณากรอกยอด Drop' : '' }}
                  </small> -->
                </b-td>
                <b-td
                  class="text-right"
                >
                  {{ Number(item.remain_amount - item.dropAmount).toLocaleString() }}
                </b-td>
                <b-td>
                  <b-form-input
                    v-model="item.dropRemark"
                    autocomplete="off"
                    placeholder="หมายเหตุ"
                    :state="item.dropAmount ? item.dropAmount && !item.dropRemark ? false : true : null"
                  />
                  <!-- <small class="text-danger">
                    {{ validationContext.errors[0] ? 'กรุณากรอกหมายเหตุ' : '' }}
                  </small> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  class="text-center"
                >
                  รวมค้างชำระ
                </b-td>
                <b-td class="text-center">
                  {{ Number(dataOverDue.length).toLocaleString() }} วัน
                </b-td>
                <b-td class="text-right">
                  {{ Number(sumDropRentalOverdueDue).toLocaleString() }}
                </b-td>
                <b-td />
                <b-td class="text-right">
                  {{ Number(sumDropRentalOverdueAmount).toLocaleString() }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(sumDropRentalOverdueNew).toLocaleString() }}
                </b-td>
                <b-td />
              </b-tr>
            </template>
            <template v-else>
              <b-tr>
                <b-td
                  colspan="7"
                  class="text-center"
                >
                  <p style="margin-top:15px; margin-bottom:15px;">
                    ไม่พบรายการ
                  </p>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <b-table-simple
          caption-top
          bordered
          class="position-relative mt-3 mb-1"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                colspan="10"
                class="text-center"
              >
                <b>ยังไม่ครบกำหนดชำระ</b>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                class="text-center"
                style="width: 100px;max-width: 100px;"
              >
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  :disabled="!regNum1 && !regNum2 ? true : false"
                  @click="fncAddNewDropList"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-th>
              <b-th
                class="text-center"
                style="width: 170px;max-width: 170px;"
              >
                <b>วันที่เริ่ม</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 170px;max-width: 170px;"
              >
                <b>วันที่สิ้นสุด</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 170px;max-width: 170px;"
              >
                <b>ค่าเช่า</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 200px;max-width: 200px;"
              >
                <b>วันที่เริ่ม</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 200px;max-width: 200px;"
              >
                <b>วันที่สิ้นสุด</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 180px;max-width: 180px;"
              >
                <b>ยอด Drop</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 170px;max-width: 170px;"
              >
                <b>จำนวนวัน</b>
              </b-th>
              <b-th
                class="text-center"
                style="width: 170px;max-width: 170px;"
              >
                <b>รวม Drop</b>
              </b-th>
              <b-th class="text-center">
                <b>หมายเหตุ</b>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="dataFutureRange.length">
              <b-tr
                v-for="(item, index) in dataFutureRange"
                :key="index"
              >
                <b-td class="text-center">
                  <b-button
                    variant="gradient-primary"
                    class="btn-icon rounded-circle"
                    @click="fncRemoveDropList(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-td>
                <b-td class="text-center">
                  {{ resolveFormatDate(item.start_date) }}
                </b-td>
                <b-td class="text-center">
                  {{ resolveFormatDate(item.end_date) }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(item.rental_amt).toLocaleString() }}
                </b-td>
                <b-td>
                  <validation-provider
                    #default="validationContext"
                    :name="`dateStart${index}`"
                    rules="required"
                  >
                    <b-form-input
                      id="inputOverDueDateStart"
                      :value="item.dateStart ? resolveFormatDate(item.dateStart) : null"
                      trim
                      readonly
                      :state="getValidationState(validationContext)"
                      @click="fncShowDatepickerFuteDateStart(index, 'dateStart')"
                    />
                    <!-- <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณาเลือกวันที่เริ่ม' : '' }}
                    </small> -->
                  </validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="validationContext"
                    :name="`dateEnd${index}`"
                    rules="required"
                  >
                    <b-form-input
                      id="inputOverDueDateEnd"
                      :value="item.dateEnd ? resolveFormatDate(item.dateEnd): null"
                      trim
                      readonly
                      :state="getValidationState(validationContext)"
                      @click="fncShowDatepickerFuteDateStart(index, 'dateEnd')"
                    />
                    <!-- <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณาเลือกวันที่สิ้นสุด' : '' }}
                    </small> -->
                  </validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="validationContext"
                    :name="`dropAmountFuture${index}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.dropAmount"
                      type="number"
                      autocomplete="off"
                      placeholder="ยอด Drop"
                      :state="getValidationState(validationContext)"
                      @input="fncUpdateFutureDateDropAmount(index)"
                    />
                    <!-- <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกยอด Drop' : '' }}
                    </small> -->
                  </validation-provider>
                </b-td>
                <b-td class="text-right">
                  {{ item.numberOfDay }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(item.dropAmount * item.numberOfDay).toLocaleString() }}
                </b-td>
                <b-td>
                  <validation-provider
                    #default="validationContext"
                    :name="`dropRemarkFuture${index}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.dropRemark"
                      autocomplete="off"
                      placeholder="หมายเหตุ"
                      :state="getValidationState(validationContext)"
                    />
                    <!-- <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกหมายเหตุ' : '' }}
                    </small> -->
                  </validation-provider>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  colspan="7"
                  class="text-center"
                >
                  รวม
                </b-td>
                <b-td class="text-right">
                  {{ Number(sumDropRentalFutureDate).toLocaleString() }}
                </b-td>
                <b-td class="text-right">
                  {{ Number(sumDropRantalFutureAmount).toLocaleString() }}
                </b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr>
                <b-td
                  colspan="10"
                  class="text-center"
                >
                  <p style="margin-top:15px; margin-bottom:15px;">
                    ไม่พบรายการ
                  </p>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="!regNum1 && !regNum2 ? true : false"
                >
                  ยืนยัน
                </b-button>
              </b-overlay>
              <b-button
                class="ml-1"
                variant="outline-secondary"
                @click="fncResetForm()"
              >
                ยกเลิก
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BForm, BTableSimple, BThead, BTh, BTbody, BTr, BTd, BOverlay,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store/index'
import {
  VDatePicker,
} from 'vuetify/lib'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

// Notification
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import DropRentalPopupRegNum from './drop-rental-popup/DropRentalPopupRegNum.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BForm,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
    VDatePicker,
    ValidationProvider,
    ValidationObserver,
    DropRentalPopupRegNum,
  },

  props: {
    salesCode: {
      type: String,
      required: true,
    },
    salesName: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appDropRental'
    // eslint-disable-next-line no-unused-vars
    const toast = useToast()

    // ประกาศตัวแปร Ref
    const refInputSearchRegNum2 = ref(null)
    const refModalDatePicker = ref(null)
    const refModalAlertDataOverDue = ref(null)
    const refModalAlertDropRemark = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const accesstime = new Date()
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const customerName = ref(null)
    const tempSearchRegNum = ref(null)
    const chassisNum = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const conNum = ref(null)
    const cooperateCode = ref(null)
    const salesMan = ref(null)
    const accCodMkt = ref(null)
    const rentTotalAmt = ref(null)
    const outStandingAmt = ref(null)
    const paidAmt = ref(null)
    const overdueAmt = ref(null)
    const futureAmt = ref(null)
    const dropAmt = ref(null)
    const outstandingPercent = ref(null)
    const paidPercent = ref(null)
    const overduePercent = ref(null)
    const futurePercent = ref(null)
    const dropPercent = ref(null)
    const dataOverDue = ref([])
    const dataFutureRange = ref([])
    const setMinDatePicker = ref(null)
    const setMaxDatePicker = ref(null)
    const indexDatePickerActive = ref(null)
    const getDatePickerActiveFrom = ref(null)

    // ประกาศตัวแปร Datepicker
    const datePickerActive = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupRegNumActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น Get Car Of Sales */
    // const fncFetchDataGerCar = () => {
    //   const payload = {
    //     action: 'VIEW',
    //     events: 'RENTAL',
    //     function: 'GET',
    //     username: JSON.parse(localStorage.getItem('userData')).member_no,
    //     accesstime: moment(accesstime).format('YYYY-MM-DD'),
    //     acccodmkt_str: props.salesCode,
    //     acccodmkt_end: props.salesCode,
    //     regnum1_in: null,
    //     regnum2_in: null,
    //     text_search: null,
    //   }

    //   store
    //     .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_OF_SALE`, payload)
    //     .then(response => {
    //       console.log(response)
    //       // eslint-disable-next-line prefer-destructuring
    //       dataResultGetCar.value = response.data.responseData[0]
    //     })
    // }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupRegNum = (search1, search2) => {
      if (search1 && search2) tempSearchRegNum.value = `${search1}-${search2}`
      else tempSearchRegNum.value = null

      isPopupRegNumActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเลขทะเบียน */
    const fncSearcRegNum = (val1, val2) => {
      if (val1 && val2) {
        const payload = {
          action: 'LIST',
          events: 'RENTAL',
          function: 'GET',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          acccodmkt_str: props.accCodMkt,
          acccodmkt_end: props.accCodMkt,
          regnum1_in: null,
          regnum2_in: null,
          text_search: val1 || val2,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_OF_SALE`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.regnum1.toLowerCase().indexOf(val1) > -1 && item.regnum2.toLowerCase().indexOf(val2) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupRegNum(val1, val2)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelRegNum = () => {
      regNum1.value = null
      regNum2.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null
      customerName.value = null
      chassisNum.value = null
      branchCode.value = null
      branchName.value = null
      conNum.value = null
      cooperateCode.value = null
      salesMan.value = null
      dataOverDue.value = []
      dataFutureRange.value = []
      rentTotalAmt.value = null
      outStandingAmt.value = null
      outstandingPercent.value = null
      paidAmt.value = null
      paidPercent.value = null
      overdueAmt.value = null
      overduePercent.value = null
      futureAmt.value = null
      futurePercent.value = null
      dropAmt.value = null
      dropPercent.value = null
    }

    /* ฟังก์ชั่นเรียก Store SP_RT_RENTAL_INQUIRY_1 */
    const fncFetchDataInquiry1 = () => {
      const payload = {
        action: 'VIEW',
        events: 'INQUIRY',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        current_date: moment(accesstime).format('YYYY-MM-DD'),
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
        chassis_number_in: chassisNum.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_RENTAL_INQUIRY_1`, payload)
        .then(response => {
          // console.log(response)
          if (response.data.responseData.length) {
            rentTotalAmt.value = response.data.responseData[0].rent_total_amt
            outStandingAmt.value = response.data.responseData[0].outstanding_amt
            paidAmt.value = response.data.responseData[0].paid_amt
            overdueAmt.value = response.data.responseData[0].outstanding_amt
            futureAmt.value = response.data.responseData[0].future_amt
            dropAmt.value = response.data.responseData[0].drop_amt
            outstandingPercent.value = response.data.responseData[0].outstanding_percent
            paidPercent.value = response.data.responseData[0].paid_percent
            overduePercent.value = response.data.responseData[0].overdue_percent
            futurePercent.value = response.data.responseData[0].future_percent
            dropPercent.value = response.data.responseData[0].drop_percent
          } else {
            rentTotalAmt.value = null
            outStandingAmt.value = null
            paidAmt.value = null
            overdueAmt.value = null
            futureAmt.value = null
            dropAmt.value = null
            outstandingPercent.value = null
            paidPercent.value = null
            overduePercent.value = null
            futurePercent.value = null
            dropPercent.value = null
          }
        })
    }

    /* ฟังก์ชั่นเรียก Store SP_RT_UPD_DROP_RENTAL */
    const fncFetchDataOverDue = () => {
      const payload = {
        action: 'LIST',
        events: 'OVER_DUE',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        run_date: moment(accesstime).format('YYYY-MM-DD'),
        drop_number_in: null,
        drop_type_in: 'EACH',
        effective_date_in: moment(accesstime).format('YYYY-MM-DD'),
        acccodmkt_in: props.salesCode,
        drop_seq_in: null,
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
        connum_in: conNum.value,
        rental_date_new_str: null,
        rental_date_new_end: null,
        drop_amount_in: null,
        drop_reason_in: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          const setDataOverDue = []
          // Filter เลือกเฉพาะวันที่ถึงปัจจุบัน
          const getresponseData = response.data.responseData.filter(item => {
            const date = new Date(item.due_date)
            return (date < accesstime)
          })
          Object.keys(getresponseData).forEach(key => {
            setDataOverDue.push({
              due_date: response.data.responseData[key].due_date,
              due_amount: response.data.responseData[key].due_amount,
              paid_amount: response.data.responseData[key].paid_amount,
              remain_amount: response.data.responseData[key].due_amount - response.data.responseData[key].paid_amount,
              dropAmount: null,
              dropRemark: null,
              // dropAmount: .value, // อย่าลืมแก้กลับ
              // dropRemark: null, // อย่าลืมแก้กลับ
            })
          })

          dataOverDue.value = setDataOverDue
        })
    }

    /* ฟังก์ชั่นเพิ่มรายการ Drop */
    const fncAddNewDropList = () => {
      const payload = {
        action: 'VIEW',
        events: 'FUTURE_RANGE',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        run_date: moment(accesstime).format('YYYY-MM-DD'),
        drop_number_in: null,
        drop_type_in: 'EACH',
        effective_date_in: !dataFutureRange.value.length ? moment(accesstime).format('YYYY-MM-DD') : dataFutureRange.value[dataFutureRange.value.length - 1].dateEnd, // '2020-01-01',
        acccodmkt_in: props.salesCode,
        drop_seq_in: null,
        regnum1_in: regNum1.value,
        regnum2_in: regNum2.value,
        connum_in: conNum.value,
        rental_date_new_str: null,
        rental_date_new_end: null,
        drop_amount_in: null,
        drop_reason_in: null,
      }

      console.log('fncAddNewDropList', payload)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          console.log(response.data.responseData)
          const setDataOverDue = []
          Object.keys(response.data.responseData).forEach(key => {
            setDataOverDue.push({
              start_date: response.data.responseData[key].start_date,
              end_date: response.data.responseData[key].end_date,
              rental_amt: response.data.responseData[key].rental_amt,
              dropStartDate: null,
              dropEndDate: null,
              numberOfDay: null,
              dropAmount: null,
              dropRemark: null,
            })
          })

          dataFutureRange.value = [...dataFutureRange.value, ...setDataOverDue]
        })
    }

    /* ฟังก์ชั่นลบรายการ Drop */
    const fncRemoveDropList = index => {
      dataFutureRange.value.splice(index, 1)
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectRegNum = data => {
      regNum1.value = data.regnum1
      regNum2.value = data.regnum2
      customerName.value = data.customer
      chassisNum.value = data.chassis_num
      branchCode.value = data.cpnbrncod
      branchName.value = data.cpnbrncod_desc
      conNum.value = data.connum
      // cooperateCode.value = ??
      salesMan.value = data.sale_name
      accCodMkt.value = data.acccodmkt

      fncFetchDataInquiry1()
      fncFetchDataOverDue()
    }

    /* ฟักง์ชั่นเปิด Popup Datepicker */
    const fncShowDatepickerFuteDateStart = (index, activeFrom) => {
      if (activeFrom === 'dateStart') {
        /* =============================== BEGIN CHECK MIN DATE (dateStart) =============================== */
        // setMinDatePicker.value = dataFutureRange.value[Number(index) - 1] && dataFutureRange.value[Number(index) - 1].dateEnd ? dataFutureRange.value[Number(index) - 1].dateEnd : null

        // if (!setMinDatePicker.value) {
        //   let currentIndexCheck = index

        //   while (currentIndexCheck > 0) {
        //     currentIndexCheck -= 1
        //     setMinDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateEnd ? dataFutureRange.value[currentIndexCheck].dateEnd : null

        //     if (!setMinDatePicker.value) setMinDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateStart ? dataFutureRange.value[currentIndexCheck].dateStart : null
        //     if (setMinDatePicker.value) break
        //   }
        // }

        // // หากแถวก่อนหน้านั้นไม่มีการกำหนดวันที่มาเลย ให้ใช้วันที่ปัจจุบันเป็นตัวตั้ง
        // if (!setMinDatePicker.value) setMinDatePicker.value = moment(dataFutureRange.value[0].start_date).format('YYYY-MM-DD')

        setMinDatePicker.value = moment(dataFutureRange.value[index].start_date).format('YYYY-MM-DD')
        /* =============================== END CHECK MIN DATE (dateStart) =============================== */

        /* =============================== BEGIN CHECK MAX DATE (dateStart) =============================== */
        // setMaxDatePicker.value = dataFutureRange.value[index].dateEnd ? dataFutureRange.value[index].dateEnd : null

        // if (!setMaxDatePicker.value) {
        //   let currentIndexCheck = index
        //   while (currentIndexCheck < dataFutureRange.value.length) {
        //     currentIndexCheck += 1
        //     setMaxDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateStart ? dataFutureRange.value[currentIndexCheck].dateStart : null

        //     if (!setMaxDatePicker.value) setMaxDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateEnd ? dataFutureRange.value[currentIndexCheck].dateEnd : null
        //     if (setMaxDatePicker.value) break
        //   }
        // }

        setMaxDatePicker.value = dataFutureRange.value[index].dateEnd ? dataFutureRange.value[index].dateEnd : moment(dataFutureRange.value[index].end_date).format('YYYY-MM-DD')
        /* =============================== BEGIN CHECK MAX DATE (dateStart) =============================== */

        datePickerActive.value = dataFutureRange.value[index] && dataFutureRange.value[index].dateStart ? dataFutureRange.value[index].dateStart : setMinDatePicker.value
      } else {
        /* =============================== BEGIN CHECK MIN DATE (dateEnd) =============================== */
        // setMinDatePicker.value = dataFutureRange.value[index] && dataFutureRange.value[index].dateStart ? dataFutureRange.value[index].dateStart : null
        // /* วนรูปเพื่อเช็คว่ามีการกรอกวันที่ก่อนหน้านี้หรือไม่ เพื่อทำ MinDatePicker */
        // if (!setMinDatePicker.value) {
        //   let currentIndexCheck = index
        //   while (currentIndexCheck > 0) {
        //     currentIndexCheck -= 1
        //     setMinDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateEnd ? dataFutureRange.value[currentIndexCheck].dateEnd : null

        //     if (!setMinDatePicker.value) setMinDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateStart ? dataFutureRange.value[currentIndexCheck].dateStart : null
        //     if (setMinDatePicker.value) break
        //   }
        // }

        // // หากแถวก่อนหน้านั้นไม่มีการกำหนดวันที่มาเลย ให้ใช้วันที่ปัจจุบันเป็นตัวตั้ง
        // if (!setMinDatePicker.value) setMinDatePicker.value = moment(dataFutureRange.value[0].start_date).format('YYYY-MM-DD')

        setMinDatePicker.value = dataFutureRange.value[index].dateStart ? dataFutureRange.value[index].dateStart : moment(dataFutureRange.value[index].start_date).format('YYYY-MM-DD')
        // setMinDatePicker.value = dataFutureRange.value[index].dropStartDate ? dataFutureRange.value[index].dropStartDate : dataFutureRange.value[index].start_date
        /* =============================== END CHECK MIN DATE (dateEnd) =============================== */

        /* =============================== BEGIN CHECK MAX DATE (dateEnd) =============================== */
        // setMaxDatePicker.value = dataFutureRange.value[Number(index) + 1] && dataFutureRange.value[Number(index) + 1].dateStart ? dataFutureRange.value[Number(index) + 1].dateStart : null

        // if (!setMaxDatePicker.value) {
        //   let currentIndexCheck = index
        //   while (currentIndexCheck < dataFutureRange.value.length) {
        //     currentIndexCheck += 1

        //     setMaxDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateStart ? dataFutureRange.value[currentIndexCheck].dateStart : null

        //     if (!setMaxDatePicker.value) setMaxDatePicker.value = dataFutureRange.value[currentIndexCheck] && dataFutureRange.value[currentIndexCheck].dateEnd ? dataFutureRange.value[currentIndexCheck].dateEnd : null
        //     if (setMaxDatePicker.value) break
        //   }
        // }

        setMaxDatePicker.value = dataFutureRange.value[index].end_date
        /* =============================== END CHECK MAX DATE (dateEnd) =============================== */

        datePickerActive.value = dataFutureRange.value[index] && dataFutureRange.value[index].dateEnd ? dataFutureRange.value[index].dateEnd : setMinDatePicker.value
      }

      indexDatePickerActive.value = index
      getDatePickerActiveFrom.value = activeFrom
      refModalDatePicker.value.show()
    }

    /* ฟังก์ชั่นเมื่อปิด Popup Datepikcer */
    const fncResetDatePickerValue = () => {
      datePickerActive.value = null
      setMinDatePicker.value = null
      setMaxDatePicker.value = null
    }

    /* ฟังก์ชั่นเมื่อคลิกเลือกวันที่ Datepicker */
    const fncClickSelectDatepicker = () => {
      const index = indexDatePickerActive.value

      if (getDatePickerActiveFrom.value === 'dateStart') dataFutureRange.value[index].dateStart = datePickerActive.value
      else if (getDatePickerActiveFrom.value === 'dateEnd') dataFutureRange.value[index].dateEnd = datePickerActive.value

      // eslint-disable-next-line no-use-before-define
      dataFutureRange.value[index].numberOfDay = dataFutureRange.value[index].dateStart && dataFutureRange.value[index].dateEnd ? resolveDateDiff(dataFutureRange.value[index].dateStart, dataFutureRange.value[index].dateEnd) : null

      refModalDatePicker.value.hide()
      fncResetDatePickerValue()
    }

    /* ฟังก์ชั่นสำหรับยิง API ของส่วนการทำงานตาราง A */
    // eslint-disable-next-line no-unused-vars
    const fncSubmitDropOverDue = payload => new Promise((resolve, reject) => {
      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          // console.log('fncSubmitDropOverDue', response.data.responseData)
          resolve(response.data.responseData[0].drop_number)
        })
        .catch(error => reject(error))
    })

    /* ฟังก์ชั่นสำหรับยิง API ของส่วนการทำงานตาราง B */
    // eslint-disable-next-line no-unused-vars
    const fncSubmitDropFutureRange = payload => new Promise((resolve, reject) => {
      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          // console.log('fncSubmitDropFutureRange', response.data.responseData)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })

    /* ฟังก์ชั่นปุ่ม Submit Form */
    const fncSubmit = async () => {
      const checkFillDropAmount = dataOverDue.value.filter(item => item.dropAmount != null)
      if (!checkFillDropAmount.length) {
        refModalAlertDataOverDue.value.show()
        return
      }

      const checkFillRemark = checkFillDropAmount.filter(item => item.dropRemark != null)
      if (!checkFillRemark.length) {
        refModalAlertDropRemark.value.show()
        return
      }

      let dropNumber = null
      let indexDataOverDue = 1

      const filterDataOverDue = dataOverDue.value.filter(item => item.dropAmount != null && item.dropRemark != null)

      // eslint-disable-next-line no-restricted-syntax
      for (const item of filterDataOverDue) {
        const payloadDropOverDue = {
          action: 'ADD',
          events: 'MAKER_EACH_OVD',
          function: 'SUBMIT',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          run_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          drop_number_in: dropNumber,
          drop_type_in: 'EACH',
          effective_date_in: moment(new Date()).format('YYYY-MM-DD'),
          acccodmkt_in: props.salesCode,
          // eslint-disable-next-line no-use-before-define
          drop_seq_in: indexDataOverDue,
          regnum1_in: regNum1.value,
          regnum2_in: regNum2.value,
          connum_in: conNum.value,
          rental_date_new_str: item.due_date,
          rental_date_new_end: item.due_date,
          drop_amount_in: Number(item.dropAmount),
          drop_reason_in: item.dropRemark,
        }

        // eslint-disable-next-line no-await-in-loop
        dropNumber = await fncSubmitDropOverDue(payloadDropOverDue, 2)
        indexDataOverDue += 1
      }

      dropNumber = null // reset val
      indexDataOverDue = 1 // reset val

      // eslint-disable-next-line no-restricted-syntax
      for (const item of dataFutureRange.value) {
        const payloadFutureRange = {
          action: 'ADD',
          event: 'MAKER_EACH_FUTURE',
          function: 'SUBMIT',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          run_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          drop_number_in: dropNumber,
          drop_type_in: 'EACH',
          effective_date_in: moment(new Date()).format('YYYY-MM-DD'),
          acccodmkt_in: props.salesCode,
          // eslint-disable-next-line no-use-before-define
          drop_seq_in: indexDataOverDue,
          regnum1_in: item.reg_num1,
          regnum2_in: item.reg_num2,
          connum_in: item.connum,
          rental_date_new_str: item.dateStart,
          rental_date_new_end: item.dateEnd,
          drop_amount_in: item.dropAmount,
          drop_reason_in: item.dropRemark,
        }

        // eslint-disable-next-line no-await-in-loop
        await fncSubmitDropFutureRange(payloadFutureRange, 2)
        indexDataOverDue += 1
      }

      toast({
        component: ToastificationContent,
        props: {
          title: 'แจ้งเตือน',
          icon: 'CheckCircleIcon',
          variant: 'success',
          text: 'ปรับปรุงค่าเช่าสำเร็จ',
        },
      })

      // redirect กลับไปฟอร์มหลักโดยเรียกใช้ emit reset form
      emit('reset-form')
    }

    /* ฟังก์ชั่นปุ่ม Reset Form */
    const fncResetForm = () => {
      emit('reset-form')
    }

    /* ฟังก์ชั่น Validate กรอกจำนวนยอดดรอปไม่เกินยอดค้างชำระ (ตารางเลยกำหนดชำระ) */
    const fncUpdateOverDueDropAmount = index => {
      if (Number(dataOverDue.value[index].dropAmount) < 0) dataOverDue.value[index].dropAmount = null
      if (Number(dataOverDue.value[index].dropAmount) > Number(dataOverDue.value[index].remain_amount)) dataOverDue.value[index].dropAmount = dataOverDue.value[index].remain_amount
    }

    /* ฟังก์ชั่น Validate กรอกจำนวนยอดดรอปไม่เกินยอดค่าเช่า (ตารางยังไม่ครบกำหนดชำระ) */
    const fncUpdateFutureDateDropAmount = index => {
      if (Number(dataFutureRange.value[index].dropAmount) < 0) dataFutureRange.value[index].dropAmount = null
      if (Number(dataFutureRange.value[index].dropAmount) > Number(dataFutureRange.value[index].rental_amt)) dataFutureRange.value[index].dropAmount = dataFutureRange.value[index].rental_amt
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const resolveDateDiff = (dateStart, dateEnd) => {
      const date1 = new Date(dateStart)
      const date2 = new Date(dateEnd)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays
    }
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* ========== ส่วนตัวแปร Computed ========== */
    const sumDropRentalOverdueDue = computed(() => dataOverDue.value.reduce((accum, item) => Number(accum) + Number(item.due_amount), 0))

    const sumDropRentalOverdueAmount = computed(() => dataOverDue.value.reduce((accum, item) => Number(accum) + Number(item.dropAmount), 0))

    const sumDropRentalOverdueNew = computed(() => dataOverDue.value.reduce((accum, item) => Number(accum) + (Number(item.remain_amount) - Number(item.dropAmount)), 0))

    const sumDropRentalFutureDate = computed(() => dataFutureRange.value.reduce((accum, item) => Number(accum) + Number(item.numberOfDay), 0))

    const sumDropRantalFutureAmount = computed(() => dataFutureRange.value.reduce((accum, item) => Number(accum) + (Number(item.dropAmount) * Number(item.numberOfDay)), 0))
    /* ========== ส่วนตัวแปร Computed ========== */

    // vee validate
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // ประกาศตัวแปร Ref
      refInputSearchRegNum2,
      refModalDatePicker,
      refModalAlertDataOverDue,
      refModalAlertDropRemark,

      // ประกาศตัวแปร Textbox
      inputSearchRegNum1,
      inputSearchRegNum2,

      // ประกาศตัวแปรสำหรับเก็บค่า
      accesstime,
      regNum1,
      regNum2,
      customerName,
      tempSearchRegNum,
      chassisNum,
      branchCode,
      branchName,
      conNum,
      cooperateCode,
      salesMan,
      rentTotalAmt,
      outStandingAmt,
      paidAmt,
      overdueAmt,
      futureAmt,
      dropAmt,
      outstandingPercent,
      paidPercent,
      overduePercent,
      futurePercent,
      dropPercent,
      dataOverDue,
      dataFutureRange,
      setMinDatePicker,
      setMaxDatePicker,
      indexDatePickerActive,
      getDatePickerActiveFrom,

      // ประกาศตัวแปร Datepicker
      datePickerActive,

      // ประกาศตัวแปร Active / Inactive
      isPopupRegNumActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupRegNum,
      fncSearcRegNum,
      fncCancelRegNum,
      fncSelectRegNum,
      fncAddNewDropList,
      fncRemoveDropList,
      fncShowDatepickerFuteDateStart,
      fncResetDatePickerValue,
      fncClickSelectDatepicker,
      fncSubmit,
      fncResetForm,
      fncUpdateOverDueDropAmount,
      fncUpdateFutureDateDropAmount,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,

      // Validation
      refFormObserver,
      getValidationState,
      required,

      // Computed
      sumDropRentalOverdueDue,
      sumDropRentalOverdueAmount,
      sumDropRentalOverdueNew,
      sumDropRentalFutureDate,
      sumDropRantalFutureAmount,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker > .modal-body {
  padding: 0px;
}

.modalDatePicker > .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>
