<template>
  <div>
    <template v-if="!isConfirmDropRental">
      <drop-rental-popup-sales
        :temp-search-sales="tempSearchSales"
        :is-popup-sales-active.sync="isPopupSalesActive"
        @select-item="fncSelectSales"
      />
      <b-card
        title="ปรับปรุงค่าเช่า"
        class="mt-1"
      >
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เซลล์"
              label-for="salesCode"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เซลล์
                </div>
              </template>
              <b-input-group v-show="!salesCode">
                <b-form-input
                  id="salesCode"
                  v-model="inputSearchSales"
                  placeholder="ค้นหาเซลล์"
                  trim
                  @keydown.enter.native="
                    fncSearchSales(inputSearchSales)
                  "
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncOpenPopupSales()"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group v-show="salesCode">
                <b-form-input
                  id="salesCode"
                  :value="salesName"
                  trim
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fncCancelSales"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="radioReportType"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เลือกแบบรายงาน
                </div>
              </template>
              <b-form-radio
                v-model="radioDropRentalType"
                value="GROUP"
                class="mt-1"
              >
                เลือกเป็นกลุ่ม
              </b-form-radio>
              <b-form-radio
                v-model="radioDropRentalType"
                value="CAR"
                class="mt-1"
              >
                เลือกเป็นรายคัน
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  :disabled="salesCode ? false : true"
                  @click="fncSubmit()"
                >
                  ยืนยัน
                </b-button>
              </b-overlay>
              <b-button
                class="ml-1"
                variant="outline-secondary"
                @click="fncResetForm()"
              >
                ยกเลิก
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-if="isConfirmDropRental && radioDropRentalType == 'GROUP'">
      <drop-rental-group-form
        :is-confirm-drop-rental="isConfirmDropRental"
        :sales-code="salesCode"
        @reset-form="fncResetForm()"
      />
    </template>
    <template v-if="isConfirmDropRental && radioDropRentalType == 'CAR'">
      <drop-rental-car-form
        :is-confirm-drop-rental.sync="isConfirmDropRental"
        :sales-code="salesCode"
        :sales-name="salesName"
        @reset-form="fncResetForm()"
      />
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormRadio, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import DropRentalPopupSales from './drop-rental-popup/DropRentalPopupSales.vue'
import DropRentalGroupForm from './DropRentalGroupForm.vue'
import DropRentalCarForm from './DropRentalCarForm.vue'
import DropRentalStoreModule from './DropRentalStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormRadio,
    BOverlay,
    DropRentalPopupSales,
    DropRentalGroupForm,
    DropRentalCarForm,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appDropRental'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, DropRentalStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    // })

    // ประกาศตัวแปร Textbox
    const inputSearchSales = ref(null)

    // ประกาศตัวแปร radio
    const radioDropRentalType = ref('GROUP')

    // ประกาศตัวแปรสำหรับเก็บค่า
    const salesCode = ref(null)
    const salesName = ref(null)
    const tempSearchSales = ref(null)
    const isConfirmDropRental = ref(false)

    // ประกาศตัวแปร Active / Inactive
    const isPopupSalesActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเปิด Popup เซลล์ */
    const fncOpenPopupSales = search => {
      if (search) tempSearchSales.value = search
      else tempSearchSales.value = null

      isPopupSalesActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncSearchSales = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'GENERAL',
          function: 'GET',
          username: null,
          access_time: null,
          cpncod_in: null,
          cpnbrncod_in: null,
          accbustyp_in: null,
          accdeawth_in: 'MKT',
          acccod_in: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccod.toLowerCase().indexOf(val) > -1 || item.account_name.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              salesCode.value = getSearchResult[0].acccod
              salesName.value = getSearchResult[0].account_name
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupSales(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเซลล์นจาก Popup */
    const fncSelectSales = data => {
      salesCode.value = data.acccod
      salesName.value = data.account_name
    }

    /* ฟังก์ชั่นยกเลิกเซลล์ */
    const fncCancelSales = () => {
      salesCode.value = null
      salesName.value = null
      inputSearchSales.value = null
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    const fncSubmit = () => {
      // eslint-disable-next-line no-console
      isConfirmDropRental.value = true
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      fncCancelSales()
      radioDropRentalType.value = 'GROUP'
      isConfirmDropRental.value = false
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    return {
      // ประกาศตัวแปร Textbox
      inputSearchSales,

      // ประกาศตัวแปร radio
      radioDropRentalType,

      // ประกาศตัวแปรสำหรับเก็บค่า
      salesCode,
      salesName,
      tempSearchSales,
      isConfirmDropRental,

      // ประกาศตัวแปร Active / Inactive
      isPopupSalesActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupSales,
      fncSearchSales,
      fncSelectSales,
      fncCancelSales,
      fncSubmit,
      fncResetForm,
    }
  },
}
</script>

<style>
/* for local only
.delete-margin {
  margin-bottom: -60px !important;
} */
</style>
