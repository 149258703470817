<template>
  <b-card
    title="สร้างรายการปรับปรุงค่าเช่า เลือกเป็นกลุ่ม"
    class="mt-1"
  >
    <drop-rental-popup-reg-num
      :temp-search-reg-num="tempSearchRegNum"
      :is-popup-reg-num-active.sync="isPopupRegNumActive"
      :acc-cod-mkt="dataResultGetCar.acccodmkt"
      open-popup-from="dropRentalGroup"
      @select-item="fncSelectRegNum"
    />
    <b-modal
      ref="refModalDatePicker"
      centered
      title="ข้อมูลวันที่"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
      @hidden="fncResetDatePickerValue()"
    >
      <v-date-picker
        v-model="datePickerActive"
        class="mt-6"
        :min="setMinDatePicker"
        :max="setMaxDatePicker"
        locale="th"
        @input="fncClickSelectDatepicker"
      />
    </b-modal>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="เซลล์"
              label-for="salesName"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  เซลล์
                </div>
              </template>
              <b-form-input
                id="salesName"
                :value="dataResultGetCar.sale_name"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="วันที่ทำรายการ"
              label-for="salesName"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  วันที่ทำรายการ
                </div>
              </template>
              <b-form-input
                id="salesName"
                :value="resolveFormatDate(accesstime)"
                trim
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col :cols="12">

            <b-table-simple
              caption-top
              bordered
              responsive
              class="position-relative mb-2"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    class="text-center"
                    style="width: 100px;"
                  >
                    <feather-icon
                      size="14"
                      icon="PlusIcon"
                      class="cursor-pointer"
                      @click="fncAddNewDropList"
                    />
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 220px;"
                  >
                    วันที่เริ่ม
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 220px;"
                  >
                    วันที่สิ้นสุด
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 220px;"
                  >
                    ยอด Drop
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 150px;"
                  >
                    จำนวนวัน
                  </b-th>
                  <b-th class="text-center">
                    หมายเหตุ
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="dataDropList.length != 0">
                  <b-tr
                    v-for="(item, index) in dataDropList"
                    :key="index"
                  >
                    <b-td class="text-center">
                      <feather-icon
                        size="14"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="fncRemoveDropList(index)"
                      />
                    </b-td>
                    <b-td class="text-center">
                      <validation-provider
                        #default="validationContext"
                        :name="`dateStart${index}`"
                        rules="required"
                      >
                        <b-form-input
                          id="inputOverDueDateStart"
                          :value="item.dateStart ? resolveFormatDate(item.dateStart) : null"
                          trim
                          readonly
                          :state="getValidationState(validationContext)"
                          @click="fncShowDatepickerOverDueDateStart(index, 'dateStart')"
                        />
                        <!-- <small class="text-danger">
                          {{ validationContext.errors[0] ? 'กรุณาเลือกวันที่เริ่ม' : '' }}
                        </small> -->
                      </validation-provider>
                    </b-td>
                    <b-td class="text-center">
                      <validation-provider
                        #default="validationContext"
                        :name="`dateEnd${index}`"
                        rules="required"
                      >
                        <b-form-input
                          id="inputOverDueDateEnd"
                          :value="item.dateEnd ? resolveFormatDate(item.dateEnd): null"
                          trim
                          readonly
                          :state="getValidationState(validationContext)"
                          @click="fncShowDatepickerOverDueDateStart(index, 'dateEnd')"
                        />
                        <!-- <small class="text-danger">
                          {{ validationContext.errors[0] ? 'กรุณาเลือกวันที่สิ้นสุด' : '' }}
                        </small> -->
                      </validation-provider>
                    </b-td>
                    <b-td>
                      <validation-provider
                        #default="validationContext"
                        :name="`dropAmount${index}`"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item.dropAmount"
                          type="number"
                          autocomplete="off"
                          placeholder="ยอด Drop"
                          :state="getValidationState(validationContext)"
                        />
                        <!-- <small class="text-danger">
                          {{ validationContext.errors[0] ? 'กรุณากรอกยอด Drop' : '' }}
                        </small> -->
                      </validation-provider>
                    </b-td>
                    <b-td class="text-center">
                      <div style="margin-top: 10px;">
                        <span>{{ item.numberOfDay }}</span>
                      </div>
                    </b-td>
                    <b-td>
                      <validation-provider
                        #default="validationContext"
                        :name="`dropRemark${index}`"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item.dropRemark"
                          autocomplete="off"
                          placeholder="หมายเหตุ"
                          :state="getValidationState(validationContext)"
                        />
                        <!-- <small class="text-danger">
                          {{ validationContext.errors[0] ? 'กรุณากรอกหมายเหตุ' : '' }}
                        </small> -->
                      </validation-provider>
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td
                      colspan="6"
                      class="text-center"
                    >
                      <p style="margin-top:15px; margin-bottom:15px;">
                        ไม่พบรายการ
                      </p>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for="selectType"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-form-radio
                v-model="radioSelectType"
                value="ALL"
                class="mt-1"
              >
                เลือกทั้งหมด (ยกเว้นบางรายการ)
              </b-form-radio>
              <b-form-radio
                v-model="radioSelectType"
                value="EACH"
                class="mt-1"
              >
                เลือกบางรายการ
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <hr>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="รวมรายการต้องการ Drop"
              label-for="sumListDrop"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  รวมรายการต้องการ Drop
                </div>
              </template>
              <b-form-input
                id="sumListDrop"
                :value="sumListDrop"
                trim
                placeholder="รวมรายการต้องการ Drop"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label="รวมรายการไม่ต้องการ Drop"
              label-for="sumListNotDrop"
              label-cols-md="3"
              label-cols-sm="12"
            >
              <template v-slot:label>
                <div style="margin-top:10px;">
                  รวมรายการไม่ต้องการ Drop
                </div>
              </template>
              <b-form-input
                id="sumListNotDrop"
                :value="sumListNotDrop"
                trim
                placeholder="รวมรายการไม่ต้องการ Drop"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="delete-margin">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-row class="delete-margin">
              <b-col>
                <b-form-group
                  label-for="inputSearchRegNum"
                  label-cols-md="3"
                  label-cols-sm="12"
                >
                  <template v-slot:label>
                    <div style="margin-top:10px;">
                      ค้นหาเลขทะเบียน
                    </div>
                  </template>
                  <b-input-group v-show="!regNum1 && !regNum2">
                    <b-form-input
                      id="inputSearchRegNum"
                      v-model="inputSearchRegNum1"
                      placeholder="เลขทะเบียน"
                      trim
                      style="display: inline;width: 40%;"
                      @keydown.enter.native="refInputSearchRegNum2.focus()"
                    />
                    <b-form-input
                      id="inputSearchRegNum2"
                      ref="refInputSearchRegNum2"
                      v-model="inputSearchRegNum2"
                      placeholder="เลขทะเบียน"
                      trim
                      style="display: inline;width: 40%;  margin-left: 5px;"
                      @keydown.enter.native="fncSearcRegNum(inputSearchRegNum1, inputSearchRegNum2)"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="fncOpenPopupRegNum()"
                      >
                        <feather-icon icon="ListIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group v-show="regNum1 || regNum2">
                    <b-form-input
                      id="inputSearchRegNum"
                      v-model="regNum1"
                      placeholder="เลขทะเบียน"
                      trim
                      style="display: inline;width: 40%;"
                      disabled
                    />
                    <b-form-input
                      id="inputSearchRegNu2"
                      v-model="regNum2"
                      placeholder="เลขทะเบียน"
                      trim
                      style="display: inline;width: 40%; margin-left: 5px;"
                      disabled
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="fncCancelRegNum()"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="customerName"
                  label-cols-md="3"
                  label-cols-sm="12"
                >
                  <b-form-input
                    id="customerName"
                    v-model="customerName"
                    placeholder="ชื่อ - นามสกุล"
                    trim
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="1"
              label-cols-sm="12"
            >
              <b-button
                variant="primary"
                :disabled="regNum1 || regNum2 ? false : true"
                @click="fncAddCustomer"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 8 : 12">

            <b-table-simple
              caption-top
              bordered
              responsive
              class="position-relative mb-2"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    class="text-center"
                    style="width: 100px;"
                  >
                    ลบ
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 300px;"
                  >
                    ทะเบียน
                  </b-th>
                  <b-th
                    class="text-center"
                    style="width: 300px;"
                  >
                    เลขสัญญา
                  </b-th>
                  <b-th
                    class="text-center"
                  >
                    ชื่อ - นามสกุล
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="dataDropCustomer.length != 0">
                  <b-tr
                    v-for="(item, index) in dataDropCustomer"
                    :key="index"
                  >
                    <b-td class="text-center">
                      <feather-icon
                        size="14"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="fncRemoveDropCustomer(index)"
                      />
                    </b-td>
                    <b-td class="text-center">
                      {{ `${item.reg_num1}-${item.reg_num2}` }}
                    </b-td>
                    <b-td class="text-center">
                      {{ item.connum }}
                    </b-td>
                    <b-td class="text-center">
                      {{ item.customer }}
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td
                      colspan="4"
                      class="text-center"
                    >
                      <p style="margin-top:10px; margin-bottom:10px;">
                        ไม่พบรายการ
                      </p>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
            <b-form-group
              label-for=""
              label-cols-md="3"
              label-cols-sm="12"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  type="submit"
                >
                  ยืนยัน
                </b-button>
              </b-overlay>
              <b-button
                class="ml-1"
                variant="outline-secondary"
                @click="fncResetForm()"
              >
                ยกเลิก
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BModal, BFormRadio, BButton, BInputGroup, BInputGroupAppend, BForm, BOverlay,
} from 'bootstrap-vue'
import { ref, onMounted, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
import {
  VDatePicker,
} from 'vuetify/lib'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import DropRentalPopupRegNum from './drop-rental-popup/DropRentalPopupRegNum.vue'
import DropRentalStoreModule from './DropRentalStoreModule'

// Notification
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BModal,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BOverlay,
    VDatePicker,
    DropRentalPopupRegNum,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isConfirmDropRental: {
      type: Boolean,
      required: true,
    },
    salesCode: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appDropRental'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, DropRentalStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    // })

    // ประกาศตัวแปร Ref
    const refModalDatePicker = ref(null)
    const refInputSearchRegNum2 = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)

    // ประกาศตัวแปร radio
    const radioSelectType = ref('ALL')

    // ประกาศตัวแปรสำหรับเก็บค่า
    const dataResultGetCar = ref([])
    const dataDropList = ref([])
    const dataDropCustomer = ref([])
    const accesstime = new Date()
    const setMinDatePicker = ref(null)
    const setMaxDatePicker = ref(null)
    const indexDatePickerActive = ref(null)
    const getDatePickerActiveFrom = ref(null)
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const tempSearchRegNum = ref(null)
    const customerName = ref(null)
    const conNum = ref(null)

    // ประกาศตัวแปร Datepicker
    const datePickerActive = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupRegNumActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น Get Car Of Sales */
    const fncFetchDataGerCar = () => {
      const payload = {
        action: 'VIEW',
        events: 'TOTAL_CAR',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        accesstime: moment(accesstime).format('YYYY-MM-DD'),
        acccodmkt_str: props.salesCode,
        acccodmkt_end: props.salesCode,
        regnum1_in: null,
        regnum2_in: null,
        text_search: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_OF_SALE`, payload)
        .then(response => {
          // console.log(response)
          // eslint-disable-next-line prefer-destructuring
          dataResultGetCar.value = response.data.responseData[0]
        })
    }

    /* ฟังก์ชั่นเพิ่มรายการ Drop */
    const fncAddNewDropList = () => {
      dataDropList.value.push({
        dateStart: null,
        dateEnd: null,
        dropAmount: null,
        numberOfDay: null,
        dropRemark: null,
      })
    }

    /* ฟังก์ชั่นลบรายการ Drop */
    const fncRemoveDropList = index => {
      dataDropList.value.splice(index, 1)
    }

    /* ฟักง์ชั่นเปิด Popup Datepicker */
    const fncShowDatepickerOverDueDateStart = (index, activeFrom) => {
      if (activeFrom === 'dateStart') {
        /* =============================== BEGIN CHECK MIN DATE (dateStart) =============================== */
        setMinDatePicker.value = dataDropList.value[Number(index) - 1] && dataDropList.value[Number(index) - 1].dateEnd ? dataDropList.value[Number(index) - 1].dateEnd : null

        if (!setMinDatePicker.value) {
          let currentIndexCheck = index

          while (currentIndexCheck > 0) {
            currentIndexCheck -= 1
            setMinDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateEnd ? dataDropList.value[currentIndexCheck].dateEnd : null

            if (!setMinDatePicker.value) setMinDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateStart ? dataDropList.value[currentIndexCheck].dateStart : null
            if (setMinDatePicker.value) break
          }
        }

        // หากแถวก่อนหน้านั้นไม่มีการกำหนดวันที่มาเลย ให้ใช้วันที่ปัจจุบันเป็นตัวตั้ง
        if (!setMinDatePicker.value) setMinDatePicker.value = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
        /* =============================== END CHECK MIN DATE (dateStart) =============================== */

        /* =============================== BEGIN CHECK MAX DATE (dateStart) =============================== */
        setMaxDatePicker.value = dataDropList.value[index].dateEnd ? dataDropList.value[index].dateEnd : null

        if (!setMaxDatePicker.value) {
          let currentIndexCheck = index
          while (currentIndexCheck < dataDropList.value.length) {
            currentIndexCheck += 1
            setMaxDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateStart ? dataDropList.value[currentIndexCheck].dateStart : null

            if (!setMaxDatePicker.value) setMaxDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateEnd ? dataDropList.value[currentIndexCheck].dateEnd : null
            if (setMaxDatePicker.value) break
          }
        }
        /* =============================== BEGIN CHECK MAX DATE (dateStart) =============================== */

        datePickerActive.value = dataDropList.value[index] && dataDropList.value[index].dateStart ? dataDropList.value[index].dateStart : setMinDatePicker.value
      } else {
        /* =============================== BEGIN CHECK MIN DATE (dateEnd) =============================== */
        setMinDatePicker.value = dataDropList.value[index] && dataDropList.value[index].dateStart ? dataDropList.value[index].dateStart : null
        /* วนรูปเพื่อเช็คว่ามีการกรอกวันที่ก่อนหน้านี้หรือไม่ เพื่อทำ MinDatePicker */
        if (!setMinDatePicker.value) {
          let currentIndexCheck = index
          while (currentIndexCheck > 0) {
            currentIndexCheck -= 1
            setMinDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateEnd ? dataDropList.value[currentIndexCheck].dateEnd : null

            if (!setMinDatePicker.value) setMinDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateStart ? dataDropList.value[currentIndexCheck].dateStart : null
            if (setMinDatePicker.value) break
          }
        }

        // หากแถวก่อนหน้านั้นไม่มีการกำหนดวันที่มาเลย ให้ใช้วันที่ปัจจุบันเป็นตัวตั้ง
        if (!setMinDatePicker.value) setMinDatePicker.value = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
        /* =============================== END CHECK MIN DATE (dateEnd) =============================== */

        /* =============================== BEGIN CHECK MAX DATE (dateEnd) =============================== */
        setMaxDatePicker.value = dataDropList.value[Number(index) + 1] && dataDropList.value[Number(index) + 1].dateStart ? dataDropList.value[Number(index) + 1].dateStart : null

        if (!setMaxDatePicker.value) {
          let currentIndexCheck = index
          while (currentIndexCheck < dataDropList.value.length) {
            currentIndexCheck += 1

            setMaxDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateStart ? dataDropList.value[currentIndexCheck].dateStart : null

            if (!setMaxDatePicker.value) setMaxDatePicker.value = dataDropList.value[currentIndexCheck] && dataDropList.value[currentIndexCheck].dateEnd ? dataDropList.value[currentIndexCheck].dateEnd : null
            if (setMaxDatePicker.value) break
          }
        }
        /* =============================== END CHECK MAX DATE (dateEnd) =============================== */

        datePickerActive.value = dataDropList.value[index] && dataDropList.value[index].dateEnd ? dataDropList.value[index].dateEnd : setMinDatePicker.value
      }

      indexDatePickerActive.value = index
      getDatePickerActiveFrom.value = activeFrom
      refModalDatePicker.value.show()
    }

    /* ฟังก์ชั่นเมื่อปิด Popup Datepikcer */
    const fncResetDatePickerValue = () => {
      datePickerActive.value = null
      setMinDatePicker.value = null
      setMaxDatePicker.value = null
    }

    /* ฟังก์ชั่นเมื่อคลิกเลือกวันที่ Datepicker */
    const fncClickSelectDatepicker = () => {
      const index = indexDatePickerActive.value

      if (getDatePickerActiveFrom.value === 'dateStart') dataDropList.value[index].dateStart = datePickerActive.value
      else if (getDatePickerActiveFrom.value === 'dateEnd') dataDropList.value[index].dateEnd = datePickerActive.value

      // eslint-disable-next-line no-use-before-define
      dataDropList.value[index].numberOfDay = dataDropList.value[index].dateStart && dataDropList.value[index].dateEnd ? resolveDateDiff(dataDropList.value[index].dateStart, dataDropList.value[index].dateEnd) : null

      refModalDatePicker.value.hide()
      fncResetDatePickerValue()
    }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupRegNum = search => {
      if (search) tempSearchRegNum.value = search
      else tempSearchRegNum.value = null

      isPopupRegNumActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเลขทะเบียน */
    const fncSearcRegNum = (val1, val2) => {
      const payload = {
        action: 'LIST',
        events: 'RENTAL',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        acccodmkt_str: props.accCodMkt,
        acccodmkt_end: props.accCodMkt,
        regnum1_in: dataResultGetCar.value.acccodmkt,
        regnum2_in: dataResultGetCar.value.acccodmkt,
        text_search: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_OF_SALE`, payload)
        .then(response => {
          const getSearchResult = response.data.responseData.filter(
            item => item.regnum1.toLowerCase().indexOf(val1) > -1 && item.regnum2.toLowerCase().indexOf(val2) > -1,
          )

          if (getSearchResult.length === 1) {
            regNum1.value = getSearchResult[0].regnum1
            regNum2.value = getSearchResult[0].regnum2
          } else if ((getSearchResult.length > 1)) {
            fncOpenPopupRegNum(`${val1}-${val2}`)
          }
        })
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelRegNum = () => {
      regNum1.value = null
      regNum2.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null
      customerName.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectRegNum = data => {
      regNum1.value = data.regnum1
      regNum2.value = data.regnum2
      customerName.value = data.customer
      conNum.value = data.connum
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มเพิ่มลูกค้า */
    const fncAddCustomer = () => {
      dataDropCustomer.value.push({
        reg_num1: regNum1.value,
        reg_num2: regNum2.value,
        customer: customerName.value,
        connum: conNum.value,
      })

      regNum1.value = null
      regNum2.value = null
      customerName.value = null
      conNum.value = null
    }

    /* ฟังก์ชั่นลบรายการลูกค้า */
    const fncRemoveDropCustomer = index => {
      dataDropCustomer.value.splice(index, 1)
    }

    /* ฟังก์ชั่นสำหรับยิง API ของส่วนการทำงานตาราง A */
    const fncSubmitDropList = payload => new Promise((resolve, reject) => {
      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          // console.log('fncSubmitDropList', response.data.responseData)
          resolve(response.data.responseData[0].drop_number)
        })
        .catch(error => reject(error))
    })

    /* ฟังก์ชั่นสำหรับยิง API ของส่วนการทำงานตาราง B */
    const fncSubmitDropCustomer = payload => new Promise((resolve, reject) => {
      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_DROP_RENTAL`, payload)
        .then(response => {
          // console.log('fncSubmitDropCustomer', response.data.responseData)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })

    /* ฟังก์ชั่นปุ่ม Submit Form */
    const fncSubmit = async () => {
      let dropNumber = 0

      // eslint-disable-next-line no-restricted-syntax
      for (const item of dataDropList.value) {
        const payloadDropList = {
          action: 'ADD',
          events: 'MAKER_ALL',
          function: 'SUBMIT',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          run_date: moment(accesstime).format('YYYY-MM-DD'),
          drop_number_in: dropNumber,
          drop_type_in: 'ALL',
          effective_date_in: moment(accesstime).format('YYYY-MM-DD'),
          acccodmkt_in: Object.keys(dataResultGetCar.value).length ? dataResultGetCar.value.acccodmkt : null,
          // eslint-disable-next-line no-use-before-define
          drop_seq_in: 1,
          regnum1_in: null,
          regnum2_in: null,
          connum_in: null,
          rental_date_new_str: item.dateStart,
          rental_date_new_end: item.dateEnd,
          drop_amount_in: Number(item.dropAmount),
          drop_reason_in: item.dropRemark,
        }

        // eslint-disable-next-line no-await-in-loop
        dropNumber = await fncSubmitDropList(payloadDropList, 2)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of dataDropCustomer.value) {
        const payloadDropCustomer = {
          action: 'ADD',
          event: 'MAKER_ALL_OMIT',
          function: 'SUBMIT',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          run_date: moment(accesstime).format('YYYY-MM-DD'),
          drop_number_in: dropNumber,
          drop_type_in: radioSelectType.value,
          effective_date_in: moment(accesstime).format('YYYY-MM-DD'),
          acccodmkt_in: Object.keys(dataResultGetCar.value).length ? dataResultGetCar.value.acccodmkt : null,
          // eslint-disable-next-line no-use-before-define
          drop_seq_in: sumListDrop.value,
          regnum1_in: item.reg_num1,
          regnum2_in: item.reg_num2,
          connum_in: item.connum,
          rental_date_new_str: null,
          rental_date_new_end: null,
          drop_amount_in: null,
          drop_reason_in: null,
        }

        // eslint-disable-next-line no-await-in-loop
        await fncSubmitDropCustomer(payloadDropCustomer, 2)
      }

      toast({
        component: ToastificationContent,
        props: {
          title: 'แจ้งเตือน',
          icon: 'CheckCircleIcon',
          variant: 'success',
          text: 'ปรับปรุงค่าเช่าสำเร็จ',
        },
      })

      // redirect กลับไปฟอร์มหลักโดยเรียกใช้ emit reset form
      emit('reset-form')
    }

    /* ฟังก์ชั่นปุ่ม Reset Form */
    const fncResetForm = () => {
      emit('reset-form')
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนโค้ดของตัวแปร Computed ========== */
    const sumListDrop = computed(() => {
      // const sumTotalCar = Object.keys(dataResultGetCar.value).length ? Number(dataResultGetCar.value.total_park_car) + Number(dataResultGetCar.value.total_rental_car) + Number(dataResultGetCar.value.total_rental_os) + Number(dataResultGetCar.value.total_repossess_car) + Number(dataResultGetCar.value.total_repossess_os) : 0
      const sumTotalCar = Object.keys(dataResultGetCar.value).length ? Number(dataResultGetCar.value.total_rental_car) : 0
      let getSumListDrop = 0

      if (radioSelectType.value === 'ALL') getSumListDrop = sumTotalCar - dataDropCustomer.value.length
      if (radioSelectType.value === 'EACH') getSumListDrop = dataDropCustomer.value.length

      return getSumListDrop
    })

    const sumListNotDrop = computed(() => {
      let getSumListDrop = 0
      if (radioSelectType.value === 'ALL') getSumListDrop = dataDropCustomer.value.length
      return getSumListDrop
    })
    /* ========== ส่วนโค้ดของตัวแปร Computed ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const resolveDateDiff = (dateStart, dateEnd) => {
      const date1 = new Date(dateStart)
      const date2 = new Date(dateEnd)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays
    }
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* Life Cycle Onmunted */
    onMounted(() => {
      fncFetchDataGerCar()
    })

    // vee validate
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // ประกาศตัวแปร Ref
      refModalDatePicker,
      refInputSearchRegNum2,

      // ประกาศตัวแปร Textbox
      inputSearchRegNum1,
      inputSearchRegNum2,

      // ประกาศตัวแปร radio
      radioSelectType,

      // ประกาศตัวแปรสำหรับเก็บค่า
      dataResultGetCar,
      dataDropList,
      dataDropCustomer,
      accesstime,
      setMinDatePicker,
      setMaxDatePicker,
      regNum1,
      regNum2,
      tempSearchRegNum,
      customerName,

      // ประกาศตัวแปร Datepicker
      datePickerActive,

      // ประกาศตัวแปร Active / Inactive
      isPopupRegNumActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncAddNewDropList,
      fncRemoveDropList,
      fncShowDatepickerOverDueDateStart,
      fncClickSelectDatepicker,
      fncResetDatePickerValue,
      fncOpenPopupRegNum,
      fncSearcRegNum,
      fncCancelRegNum,
      fncSelectRegNum,
      fncAddCustomer,
      fncRemoveDropCustomer,
      fncSubmit,
      fncResetForm,

      // ส่วนโค้ดของตัวแปร Computed
      sumListDrop,
      sumListNotDrop,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
      resolveDateDiff,

      // Validation
      refFormObserver,
      getValidationState,
      required,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>
