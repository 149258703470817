import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SP_USER_WORK_SCOPE(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchUserworkScope_', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_GET_ACCOUNT_MASTER(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchGetAccountMaster', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_CAR_OF_SALE(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/DropRental/GetCarofSale', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_UPD_DROP_RENTAL(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/DropRental/UpdDropRental', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_RENTAL_INQUIRY_1(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/RepaymentInquiry/rentInq1', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
